import { all } from 'redux-saga/effects';
import cancelMemberShipSaga from './cancelMemberShip/sagas';

function * rootSaga () {
  yield all([
    cancelMemberShipSaga()
  ]);
}

export default rootSaga;
