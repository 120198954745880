import {
  CANCEL_MEMBERSHIP_SEND_OTP,
  CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS,
  CANCEL_MEMBERSHIP_SEND_OTP_FAILED,
  CANCEL_MEMBERSHIP_RESET_OTP_SENT,
  CANCEL_MEMBERSHIP_VERIFY_OTP,
  CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS,
  CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED,
  CANCEL_MEMBERSHIP_LOGOUT_USER,
  CANCEL_MEMBERSHIP_SET_STATES,
  CANCEL_MEMBERSHIP_OF_USER_SUCCESS,
  CANCEL_MEMBERSHIP_OF_USER,
  CANCEL_MEMBERSHIP_OF_USER_FAILED,
  CANCEL_MEMBERSHIP_SEND_OTP_RESET
} from './actionType';
import { type OtpActions, type LoginState, PAGE_NAME } from './types';

const initialState: LoginState = {
  token: '',
  pageName: PAGE_NAME?.SENT_OTP_PAGE,
  mobile: '',
  otpSent: false,
  isLoading: false,
  isAuthenticated: false,
  errorVerifyOtp: false,
  errorSendingOtp: {
    date: '',
    errorCode: '',
    message: ''
  },
  isCancelledMemberShip: '',
  errorCancelledMemberShip: false,
  phoneCountryCode: '',
  countryCode: ''
};

const defaultState: LoginState = JSON.parse(JSON.stringify(initialState));

export const reducer = (state = defaultState, action: OtpActions): LoginState => {
  switch (action.type) {
    case CANCEL_MEMBERSHIP_SEND_OTP:
      return {
        ...state,
        isLoading: true,
        mobile: action.payload.mobile,
        errorVerifyOtp: false,
        token: ''
      };
    case CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpSent: action?.payload?.otpSent,
        token: '',
        errorSendingOtp: {
          message: '',
          date: '',
          errorCode: ''
        }
      };

    case CANCEL_MEMBERSHIP_SEND_OTP_FAILED:
      return { ...state, isLoading: false, errorSendingOtp: action.payload.error, token: '' };
    case CANCEL_MEMBERSHIP_RESET_OTP_SENT:
      return { ...state, otpSent: action?.payload?.otpSent };
    case CANCEL_MEMBERSHIP_VERIFY_OTP:
      return { ...state, isLoading: true, token: '' };
    case CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        isAuthenticated: action.payload.token?.length > 0,
        errorVerifyOtp: false
      };
    case CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED:
      return { ...state, isLoading: false, errorVerifyOtp: action.payload.error, token: '' };
    case CANCEL_MEMBERSHIP_OF_USER:
      return { ...state, isLoading: true };
    case CANCEL_MEMBERSHIP_OF_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCancelledMemberShip: action.payload.cancelledMemberShip,
        errorCancelledMemberShip: false,
        token: '',
        isAuthenticated: false
      };
    case CANCEL_MEMBERSHIP_OF_USER_FAILED:
      return { ...state, isLoading: false, errorCancelledMemberShip: action.payload.error, token: '' };
    case CANCEL_MEMBERSHIP_SET_STATES:
      return { ...state, ...action?.payload };
    case CANCEL_MEMBERSHIP_LOGOUT_USER:
      return JSON.parse(JSON.stringify(initialState)) as LoginState;
    case CANCEL_MEMBERSHIP_SEND_OTP_RESET:
      return {
        ...state,
        isLoading: false,
        otpSent: false,
        errorSendingOtp: {
          message: '',
          date: '',
          errorCode: ''
        }
      };
    default:
      return state;
  }
};

export default reducer;
