import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  confirmTitle: {
    color: '#262626',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px'
  },
  confirmSubTitle: {
    color: '#262626',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '34px'
  },
  learnMore: {
    color: '#262626',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    textDecorationLine: 'underline'
  },
  arrow_forward: {
    alignSelf: 'flex-start',
    marginLeft: '10px'
  },
  sendOtpBtn: {
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: '#EB8200',
    borderRadius: '24px',
    border: 'none',
    color: '#000',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer'
  },
  mentionMobileNumber: {
    color: '#262626',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px'
  },
  selectBox: {
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginRight: '20px',
    display: 'flex',
    borderRadius: '4px 0 0 4px'
  },
  inputBox: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    display: 'flex',
    flex: 1,
    border: '1px solid #C2C7D0',
    borderRadius: '4px',
    width: '-webkit-fill-available'
  },
  inputBoxContainer: {
  },
  mobileNumberLabel: {
    color: '#14151C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    margin: '0px',
    paddingBottom: '8px',
    paddingTop: '27px'
  },
  countryLabel: {
    color: '#14151C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    margin: '10px 0px 8px'
  },
  inputLabel: {
    color: '#14151C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    margin: '10px 0 8px'
  },
  selectInput: {
    display: 'flex',
    width: '100%',
    border: '1px solid #C2C7D0',
    borderRadius: '4px',
    padding: '15px'
  },
  codeInputContainer: {
    width: '20%'
  },
  mobileInputContainer: {
    width: '80%',
    marginLeft: '10px'
  },
  codeMobileNumberContainer: {
    display: 'flex',
    flex: 1
  }
}));
