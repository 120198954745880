import React, { useEffect } from 'react';
import { useStyles } from './accountDeletedComponentStyle';
import { useCancelMemberShip } from '../../../hooks/useCancelMemberShip';
import { PAGE_NAME } from '../../../redux/cancelMemberShip/types';

export const AccountDeletedComponent: React.FC<any> = () => {
  const classes = useStyles();

  const { setCancelMembershipComponentStates, cancelUserMembership, token } =
    useCancelMemberShip();

  useEffect(() => {
    if (token) {
      cancelUserMembership();
    }
  }, [token]);

  return (
    <>
      <div>
        <label className={classes.confirmTitle}>
          Your account has been deleted
        </label>
      </div>
      <div style={{ margin: '34px 0 40px' }}>
        <label className={classes.confirmSubTitle}>
          Account has been cancel, it has taken offline immediately.
        </label>
      </div>
      <button
        className={classes.sendOtpBtn}
        onClick={() => {
          setCancelMembershipComponentStates({
            pageName: PAGE_NAME.SENT_OTP_PAGE
          });
        }}
      >
        Back To Home
        <img
          className={classes.arrow_forward}
          src={`${process.env.PUBLIC_URL}/keyboard_forward_arrow.png`}
          alt="expand-more"
        />
      </button>
    </>
  );
};
