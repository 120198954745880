import { type AppState, type Selector } from '../rootReducer';
import { type PAGE_NAME_TYPE, type ErrorType } from './types';

export const getIsLoading: Selector<boolean> = (state: AppState) =>
  state.cancelMemberShip.isLoading;

export const getOtpSent: Selector<boolean | null> = (state: AppState) =>
  state.cancelMemberShip.otpSent;

export const getMobile: Selector<string> = (state: AppState) =>
  state.cancelMemberShip.mobile;

export const getPageName: Selector<PAGE_NAME_TYPE> = (state: AppState) =>
  state.cancelMemberShip.pageName;

export const getCountryCode: Selector<string> = (state: AppState) =>
  state.cancelMemberShip.countryCode;

export const getPhoneCountryCode: Selector<string> = (state: AppState) =>
  state.cancelMemberShip.phoneCountryCode;

export const getIsCancelledMemberShip: Selector<string> = (state: AppState) =>
  state.cancelMemberShip.isCancelledMemberShip;

export const getErrorCancelledMemberShip: Selector<boolean> = (state: AppState) =>
  state.cancelMemberShip.errorCancelledMemberShip;

export const getToken: Selector<string> = (state: AppState) =>
  state.cancelMemberShip.token;

export const getIsAuthenticated: Selector<boolean> = (state: AppState) =>
  state.cancelMemberShip.isAuthenticated;

export const getErrorSendingOtp: Selector<ErrorType> = (state: AppState) =>
  state.cancelMemberShip.errorSendingOtp;

export const getErrorVerifyOtp: Selector<boolean> = (state: AppState) =>
  state.cancelMemberShip.errorVerifyOtp;
