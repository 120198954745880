import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  dataContainer: {
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #F1F1F4'
  },
  lossDataContainer: {
    display: 'flex',
    marginBottom: '12px'
  },
  lassDataTitle: {
    color: '#202124',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
    display: 'flex'
  },
  lassDatasubTitle: {
    color: '#5F6368',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px'
  },
  staticDataItemContainer: {
    paddingBottom: '20px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '10px',
    display: 'flex',
    cursor: 'pointer'
  },
  staticDataImg: {
    width: '24px',
    height: '24px'
  },
  staticDataTitleContainer: {
    flex: 1,
    paddingRight: '18px',
    paddingLeft: '18px'
  },
  expandDataTitleContainer: {
    flex: 1
  },
  arrow_down: {
    alignSelf: 'flex-start'
  },
  staticDataTitle: {
    color: '#202124',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    display: 'flex'
  },
  staticDatasubTitle: {
    color: '#5F6368',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px'
  },
  expandDataItemContainer: {
    border: '1px solid #B7BBC1',
    borderRadius: '8px',
    paddingLeft: '36px',
    paddingRight: '36px',
    paddingTop: '15px',
    paddingBottom: '15px',
    cursor: 'pointer'
  },
  purposeTitle: {
    color: '#5F6368',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px'
  },
  purposeTitleContainer: {
    flex: 1,
    display: 'flex'
  },
  error_outline: {
    alignSelf: 'center',
    width: '16px',
    height: '16px',
    marginLeft: '4px'
  },
  expandsubTitle: {
    color: '#202124',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.2px'
  },
  otionalLabel: {
    color: '#5F6368',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    paddingLeft: '4px'
  },
  appFunctionalitylabel: {
    color: '#5F6368',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.2px'
  },
  expandsubTitleContainer: {
    display: 'flex'
  },
  cloudImgContainer: {
    width: '56px',
    height: '56px',
    borderRadius: '56px',
    backgroundColor: '#D9D9D9',
    marginRight: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cloudImg: {
    width: '33px',
    height: '33px'
  }
}));
