import React from 'react';
import { useStyles } from './styles';
import { StaticDataComponent } from './staticDataComponent/staticDataComponent';
import { AccountDeletedComponent } from './accountDeletedComponent/accountDeletedComponent';
import { SendOtpComponent } from './sendOtpComponent/sendOtpComponent';
import { useCancelMemberShip } from '../../hooks/useCancelMemberShip';
import { PAGE_NAME } from '../../redux/cancelMemberShip/types';
import { VerifyOtpComponent } from './verifyOtpComponent/verifyOtpComponent';

const CancelMemberShip: React.FC = () => {
  const classes = useStyles();

  const { pageName } = useCancelMemberShip();

  const getSection = () => {
    switch (pageName) {
      case PAGE_NAME?.SENT_OTP_PAGE:
        return <SendOtpComponent/>;
      case PAGE_NAME?.VERIFY_OTP_PAGE:
        return <VerifyOtpComponent/>;
      case PAGE_NAME?.ACCOUNT_DELETED_PAGE:
        return <AccountDeletedComponent/>;
      default:
        return <SendOtpComponent/>;
    }
  };

  return (<>
  <div className={classes.headerContainer}>
        <img
              className={classes.growerIcon}
              src={`${process.env.PUBLIC_URL}/cropwiseGrowerIcon.png`}
              alt='expand-more'
            />
        </div>
    <div className={classes.mainContainer}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}
      >
        <div style={{ flex: 1 }}>
          {
            getSection()
          }
        </div>
        <div style={{ width: '10%' }}></div>
        <div style={{ flex: 1 }}>
          <StaticDataComponent/>
        </div>
      </div>
    </div>
    </>
  );
};
export default CancelMemberShip;
