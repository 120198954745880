import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingLeft: '20%',
    paddingRight: '20%',
    paddingTop: '180px',
    flexDirection: 'row',
    paddingBottom: '80px'
  },
  link: {
    color: '#212121',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  forwordSlash: {
    color: '#212121',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'uppercase',
    marginLeft: 4,
    marginRight: 4
  },
  divider: {
    marginBottom: '80px'
  },
  headerContainer: {
    backgroundColor: '#00004B',
    paddingLeft: '20%',
    paddingRight: '20%',
    paddingTop: '30px',
    paddingBottom: '30px',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  growerIcon: {
    width: '136px',
    height: '49px'
  }

}));
