import { useSelector, useDispatch } from 'react-redux';
import {
  getErrorSendingOtp,
  getErrorVerifyOtp,
  getIsAuthenticated,
  getIsLoading,
  getMobile,
  getOtpSent,
  getToken,
  getPageName,
  getErrorCancelledMemberShip,
  getIsCancelledMemberShip,
  getCountryCode,
  getPhoneCountryCode
} from '../redux/cancelMemberShip/selectors';
import { requestOtp, resetOtpSent as resetOtp, requestVerifyOtp, logoutUser as logout, setCancelMemberShipStates, requestCancelMembershipOfUser, requestOtpReset } from '../redux/cancelMemberShip/actions';
import { type PAGE_NAME_TYPE, type ErrorType, type LoginState, type SendOtpRequestPayload, type VerifyOtpRequestPayload } from '../redux/cancelMemberShip/types';

interface LoginStateType extends LoginState {
  sendOtp: (action: SendOtpRequestPayload) => void
  verifyOtp: (action: VerifyOtpRequestPayload) => void
  setCancelMembershipComponentStates: (action: any) => void
  resetRequestOtp: () => void
  resetOtpSent: () => void
  logoutUser: () => void
  cancelUserMembership: () => void
}

export const useCancelMemberShip = (): LoginStateType => {
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(getIsLoading);
  const otpSent: boolean | null = useSelector(getOtpSent);
  const isAuthenticated: boolean = useSelector(getIsAuthenticated);
  // const userProfile: UserProfile = useSelector(getUserProfile);
  const errorSendingOtp: ErrorType = useSelector(getErrorSendingOtp);
  const errorVerifyOtp: boolean = useSelector(getErrorVerifyOtp);
  const mobile: string = useSelector(getMobile);
  const token: string = useSelector(getToken);
  const pageName: PAGE_NAME_TYPE = useSelector(getPageName);
  const phoneCountryCode: string = useSelector(getPhoneCountryCode);
  const countryCode: string = useSelector(getCountryCode);
  const isCancelledMemberShip: string = useSelector(getIsCancelledMemberShip);
  const errorCancelledMemberShip: boolean = useSelector(getErrorCancelledMemberShip);

  const sendOtp = (action: SendOtpRequestPayload) => {
    dispatch(requestOtp(action));
  };
  const setCancelMembershipComponentStates = (action: any) => {
    dispatch(setCancelMemberShipStates(action));
  };

  const resetOtpSent = () => {
    dispatch(resetOtp({ otpSent: false }));
  };

  const resetRequestOtp = () => {
    dispatch(requestOtpReset());
  };

  const verifyOtp = (action: VerifyOtpRequestPayload) => {
    dispatch(requestVerifyOtp(action));
  };

  const logoutUser = () => {
    dispatch(logout());
  };
  const cancelUserMembership = () => {
    dispatch(requestCancelMembershipOfUser({}));
  };

  return {
    mobile,
    isLoading,
    otpSent,
    token,
    phoneCountryCode,
    pageName,
    // userProfile,
    isAuthenticated,
    errorVerifyOtp,
    errorSendingOtp,
    isCancelledMemberShip,
    errorCancelledMemberShip,
    countryCode,
    sendOtp,
    resetOtpSent,
    verifyOtp,
    logoutUser,
    cancelUserMembership,
    setCancelMembershipComponentStates,
    resetRequestOtp
  };
};
