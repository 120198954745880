import { combineReducers, type AnyAction } from 'redux';
import cancelMemberShipReducer from './cancelMemberShip/reducer';

export const appReducer = combineReducers({
  cancelMemberShip: cancelMemberShipReducer
});

export type AppState = ReturnType<typeof appReducer>;

export type Selector<T> = (state: AppState) => T;

const rootReducer = (state: AppState, action: AnyAction) => {
  return appReducer(state, action);
};

export default rootReducer;
