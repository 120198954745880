import React from 'react';
import './App.css';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CancelMemberShip from './components/cancelMemberShip';

function App () {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
              <Route path='*' element={<CancelMemberShip/>} />
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
