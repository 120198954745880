import {
  type CANCEL_MEMBERSHIP_RESET_OTP_SENT,
  type CANCEL_MEMBERSHIP_SEND_OTP,
  type CANCEL_MEMBERSHIP_SEND_OTP_FAILED,
  type CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS,
  type CANCEL_MEMBERSHIP_VERIFY_OTP,
  type CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS,
  type CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED,
  type CANCEL_MEMBERSHIP_LOGOUT_USER,
  type CANCEL_MEMBERSHIP_SET_STATES,
  type CANCEL_MEMBERSHIP_OF_USER_SUCCESS,
  type CANCEL_MEMBERSHIP_OF_USER_FAILED,
  type CANCEL_MEMBERSHIP_OF_USER,
  type CANCEL_MEMBERSHIP_SEND_OTP_RESET
} from './actionType';

export interface ErrorType {
  date: string
  message: string
  errorCode: string
}

export enum PAGE_NAME {
  SENT_OTP_PAGE = 'SENT_OTP_PAGE',
  VERIFY_OTP_PAGE = 'VERIFY_OTP_PAGE',
  ACCOUNT_DELETED_PAGE = 'ACCOUNT_DELETED_PAGE'
}
export type PAGE_NAME_TYPE = PAGE_NAME.SENT_OTP_PAGE | PAGE_NAME.VERIFY_OTP_PAGE | PAGE_NAME.ACCOUNT_DELETED_PAGE;

export interface LoginState {
  pageName: PAGE_NAME_TYPE
  token: string
  mobile: string
  otpSent: boolean | null
  isLoading: boolean
  isAuthenticated: boolean
  errorVerifyOtp: boolean
  errorSendingOtp: ErrorType
  isCancelledMemberShip: string
  errorCancelledMemberShip: boolean
  phoneCountryCode: string
  countryCode: string
}

export interface SendOtpRequestPayload {
  mobile: string
  phoneCountryCode: string
}

export interface SendOtpRequestSuccessPayload {
  otpSent: boolean
}

export interface SendOtpRequestFailedPayload {
  error: ErrorType
}

export interface VerifyOtpRequestPayload {
  mobile: string
  identifier: string
  phoneCountryCode: string
}

export interface UserProfile {
  id: string
  operatorId: number | null
  userRole: string
  userId: string
  operatorName: string
  uniqueDistrictId: number | null
  uniqueTalukaId: number | null
  isActive: number | null
  isAnanthamUser: boolean | null
  mobileNo: string | null
}

export interface VerifyOtpRequestSuccessPayload {
  token: string
  userProfile: UserProfile
}

export interface SendOtpResetPayload {
  type: typeof CANCEL_MEMBERSHIP_SEND_OTP_RESET
  payload: any
}

export interface VerifyOtpRequestFailedPayload {
  error: boolean
}

export interface VerifyOtpRequest {
  type: typeof CANCEL_MEMBERSHIP_VERIFY_OTP
  payload: VerifyOtpRequestPayload
}

export interface VerifyOtpRequestSuccess {
  type: typeof CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS
  payload: VerifyOtpRequestSuccessPayload
}

export interface VerifyOtpRequestFailed {
  type: typeof CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED
  payload: VerifyOtpRequestFailedPayload
}

export interface SendOtpRequest {
  type: typeof CANCEL_MEMBERSHIP_SEND_OTP
  payload: SendOtpRequestPayload
}

export interface SendOtpRequestSuccess {
  type: typeof CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS
  payload: SendOtpRequestSuccessPayload
}

export interface SendOtpRequestFailed {
  type: typeof CANCEL_MEMBERSHIP_SEND_OTP_FAILED
  payload: SendOtpRequestFailedPayload
}

export interface ResetOtpSent {
  type: typeof CANCEL_MEMBERSHIP_RESET_OTP_SENT
  payload: SendOtpRequestSuccessPayload
}
export interface setCancelMemberShipStates {
  type: typeof CANCEL_MEMBERSHIP_SET_STATES
  payload: any
}

export interface LogOutUser {
  type: typeof CANCEL_MEMBERSHIP_LOGOUT_USER
}

export interface CancelMembershipOfUserSuccessPayload {
  cancelledMemberShip: string
}

export interface CancelMembershipOfUserFailedPayload {
  error: boolean
}

export interface CancelMembershipOfUserRequest {
  type: typeof CANCEL_MEMBERSHIP_OF_USER
  payload: null
}

export interface CancelMembershipOfUserSuccess {
  type: typeof CANCEL_MEMBERSHIP_OF_USER_SUCCESS
  payload: CancelMembershipOfUserSuccessPayload
}

export interface CancelMembershipOfUserFailed {
  type: typeof CANCEL_MEMBERSHIP_OF_USER_FAILED
  payload: CancelMembershipOfUserFailedPayload
}
export type OtpActions =
  SendOtpRequest
  | SendOtpRequestSuccess
  | SendOtpRequestFailed
  | ResetOtpSent
  | VerifyOtpRequest
  | VerifyOtpRequestSuccess
  | VerifyOtpRequestFailed
  | CancelMembershipOfUserRequest
  | CancelMembershipOfUserSuccess
  | CancelMembershipOfUserFailed
  | LogOutUser
  | setCancelMemberShipStates
  | SendOtpResetPayload
  ;
