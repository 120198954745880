export const CANCEL_MEMBERSHIP_SEND_OTP = 'CANCEL_MEMBERSHIP_SEND_OTP';
export const CANCEL_MEMBERSHIP_SET_STATES = 'CANCEL_MEMBERSHIP_SET_STATES';
export const CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS = 'CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS';
export const CANCEL_MEMBERSHIP_SEND_OTP_FAILED = 'CANCEL_MEMBERSHIP_SEND_OTP_FAILED';
export const CANCEL_MEMBERSHIP_RESET_OTP_SENT = 'CANCEL_MEMBERSHIP_RESET_OTP_SENT';
export const CANCEL_MEMBERSHIP_VERIFY_OTP = 'CANCEL_MEMBERSHIP_VERIFY_OTP';
export const CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS = 'CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS';
export const CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED = 'CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED';
export const CANCEL_MEMBERSHIP_LOGOUT_USER = 'CANCEL_MEMBERSHIP_LOGOUT_USER';
export const CANCEL_MEMBERSHIP_OF_USER = 'CANCEL_MEMBERSHIP_OF_USER';
export const CANCEL_MEMBERSHIP_OF_USER_FAILED = 'CANCEL_MEMBERSHIP_OF_USER_FAILED';
export const CANCEL_MEMBERSHIP_OF_USER_SUCCESS = 'CANCEL_MEMBERSHIP_OF_USER_SUCCESS';
export const CANCEL_MEMBERSHIP_SEND_OTP_RESET = 'CANCEL_MEMBERSHIP_SEND_OTP_RESET';
