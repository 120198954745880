import {
  CANCEL_MEMBERSHIP_LOGOUT_USER,
  CANCEL_MEMBERSHIP_SET_STATES,
  CANCEL_MEMBERSHIP_RESET_OTP_SENT,
  CANCEL_MEMBERSHIP_SEND_OTP,
  CANCEL_MEMBERSHIP_SEND_OTP_FAILED,
  CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS,
  CANCEL_MEMBERSHIP_SEND_OTP_RESET,
  CANCEL_MEMBERSHIP_VERIFY_OTP,
  CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED,
  CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS,
  CANCEL_MEMBERSHIP_OF_USER,
  CANCEL_MEMBERSHIP_OF_USER_FAILED,
  CANCEL_MEMBERSHIP_OF_USER_SUCCESS
} from './actionType';
import {
  type ResetOtpSent,
  type SendOtpRequest,
  type SendOtpRequestFailed,
  type SendOtpRequestFailedPayload,
  type SendOtpRequestPayload,
  type SendOtpRequestSuccess,
  type SendOtpRequestSuccessPayload,
  type VerifyOtpRequestPayload,
  type VerifyOtpRequestSuccessPayload,
  type VerifyOtpRequestFailedPayload,
  type VerifyOtpRequest,
  type VerifyOtpRequestSuccess,
  type VerifyOtpRequestFailed,
  type CancelMembershipOfUserFailed,
  type CancelMembershipOfUserFailedPayload,
  type CancelMembershipOfUserRequest,
  type CancelMembershipOfUserSuccess,
  type CancelMembershipOfUserSuccessPayload
} from './types';

export const requestOtp = (payload: SendOtpRequestPayload): SendOtpRequest => {
  return {
    type: CANCEL_MEMBERSHIP_SEND_OTP,
    payload
  };
};

export const requestOtpSuccess = (payload: SendOtpRequestSuccessPayload): SendOtpRequestSuccess => {
  return {
    type: CANCEL_MEMBERSHIP_SEND_OTP_SUCCESS,
    payload
  };
};

export const requestOtpFailed = (payload: SendOtpRequestFailedPayload): SendOtpRequestFailed => {
  return {
    type: CANCEL_MEMBERSHIP_SEND_OTP_FAILED,
    payload
  };
};

export const requestOtpReset = () => {
  return {
    type: CANCEL_MEMBERSHIP_SEND_OTP_RESET
  };
};

export const requestVerifyOtp = (payload: VerifyOtpRequestPayload): VerifyOtpRequest => {
  return {
    type: CANCEL_MEMBERSHIP_VERIFY_OTP,
    payload
  };
};

export const requestVerifyOtpSuccess = (payload: VerifyOtpRequestSuccessPayload): VerifyOtpRequestSuccess => {
  return {
    type: CANCEL_MEMBERSHIP_VERIFY_OTP_SUCCESS,
    payload
  };
};

export const requestVerifyOtpFailed = (payload: VerifyOtpRequestFailedPayload): VerifyOtpRequestFailed => {
  return {
    type: CANCEL_MEMBERSHIP_VERIFY_OTP_FAILED,
    payload
  };
};

export const resetOtpSent = (payload: SendOtpRequestSuccessPayload): ResetOtpSent => {
  return {
    type: CANCEL_MEMBERSHIP_RESET_OTP_SENT,
    payload
  };
};

export const logoutUser = () => {
  return {
    type: CANCEL_MEMBERSHIP_LOGOUT_USER
  };
};

export const setCancelMemberShipStates = (payload: any) => {
  return {
    type: CANCEL_MEMBERSHIP_SET_STATES,
    payload
  };
};

export const requestCancelMembershipOfUser = (payload: any): CancelMembershipOfUserRequest => {
  return {
    type: CANCEL_MEMBERSHIP_OF_USER,
    payload
  };
};

export const requestCancelMembershipOfUserSuccess = (payload: CancelMembershipOfUserSuccessPayload): CancelMembershipOfUserSuccess => {
  return {
    type: CANCEL_MEMBERSHIP_OF_USER_SUCCESS,
    payload
  };
};

export const requestCancelMembershipOfUserFailed = (payload: CancelMembershipOfUserFailedPayload): CancelMembershipOfUserFailed => {
  return {
    type: CANCEL_MEMBERSHIP_OF_USER_FAILED,
    payload
  };
};
