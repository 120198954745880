import React, { type RefObject, createRef, useState, useEffect } from 'react';
import { useStyles } from './verifyOtpComponentStyle';
import { Link } from 'react-router-dom';
import { useCancelMemberShip } from '../../../hooks/useCancelMemberShip';
import { PAGE_NAME } from '../../../redux/cancelMemberShip/types';
import { OTP_LOCALSTORAGE_KEY, RegEx } from '../../../constants';

export const VerifyOtpComponent: React.FC<any> = () => {
  const classes = useStyles();

  const {
    mobile,
    setCancelMembershipComponentStates,
    verifyOtp,
    sendOtp,
    otpSent,
    resetOtpSent,
    phoneCountryCode,
    token
  } = useCancelMemberShip();
  const fields: Array<RefObject<HTMLInputElement>> = [1, 2, 3, 4, 5, 6].map(
    () => createRef()
  );
  const otpSentTimestamp = localStorage.getItem(OTP_LOCALSTORAGE_KEY);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [otpText, setOtpText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);

  useEffect(() => {
    if (otpSent) {
      resetOtpSent();
      const timestamp: string = Date.now().toString();
      localStorage.setItem(OTP_LOCALSTORAGE_KEY, timestamp);
    }
  }, [otpSent]);

  useEffect(() => {
    if (token && token?.length) {
      setCancelMembershipComponentStates({
        pageName: PAGE_NAME.ACCOUNT_DELETED_PAGE
      });
    }
  }, [token]);

  const interval = setInterval(() => {
    if (otpSentTimestamp) {
      if (otpSentTimestamp) {
        const otpSentTime = new Date(parseInt(otpSentTimestamp));
        const currentDate = new Date(Date.now());
        const difference = currentDate.getTime() - otpSentTime.getTime();
        const remainingTime: number =
          Math.round(Math.abs(difference / 1000)) - 60;
        if (remainingTime <= 0) {
          setRemainingSeconds(remainingTime * -1);
        }
      }
    }
  });

  const removeTimeStamp = () => {
    localStorage.removeItem('timestamp');
  };

  if (remainingSeconds && remainingSeconds <= 0) {
    removeTimeStamp();
    clearInterval(interval);
  }
  const handleVerifyOtp = () => {
    setErrorMessage('');
    if (otpText && otpText.length === 6) {
      verifyOtp({
        mobile,
        identifier: otpText,
        phoneCountryCode
      });
    } else {
      setErrorMessage('Please enter valid otp');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    let { value } = event.target;
    if (value?.length > 1) {
      value = value.slice(-1);
      const currentInput: HTMLInputElement | null =
        fields?.[currentIndex]?.current;
      if (currentInput != null) {
        currentInput.value = value;
      }
    }
    let nextIndex: number = currentIndex;
    if (value && currentIndex <= 4) {
      nextIndex = currentIndex + 1;
    } else if (!value && currentIndex > 0) {
      nextIndex = currentIndex - 1;
    }
    const nextInput: HTMLElement | null = document.getElementById(
      `otpInp${nextIndex}`
    );
    if (nextInput != null) {
      nextInput.focus();
    }
    getOtpText();
    setCurrentIndex(nextIndex);
  };

  const getOtpText = () => {
    let otp: string = '';
    fields.forEach((input: RefObject<HTMLInputElement>, index: number) => {
      const inputElement: any | null = document.getElementById(
        `otpInp${index}`
      );
      if (inputElement != null) {
        otp = `${otp}${inputElement.value}`;
      }
    });
    setOtpText(otp);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, code } = event;

    if (key.match(RegEx.OnlyDigits) == null && code !== 'Backspace') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (code === 'Backspace' && currentIndex > 0 && otpText.length === 0) {
      const index: number = currentIndex - 1;
      setCurrentIndex(index);
      const nextInput: HTMLElement | null = document.getElementById(
        `otpInp${index}`
      );
      if (nextInput != null) {
        nextInput.focus();
      }
    }
  };

  const handleResend = () => {
    sendOtp({
      mobile,
      phoneCountryCode
    });
  };

  return (
    <>
      <div>
        <label className={classes.confirmTitle}>
          Confirm your cancellation
        </label>
      </div>
      <div style={{ marginTop: '24px' }}>
        <label className={classes.confirmSubTitle}>
          If you cancel your Cropwise subscription, it will be taken offline
          immediately.{' '}
        </label>
      </div>
      <div>
        <div className={classes.inputLabelContainer}>
          <p className={classes.inputLabel}>
            OTP sent on {mobile}{' '}
            <Link
              to={''}
              onClick={() => {
                setCancelMembershipComponentStates({
                  pageName: PAGE_NAME.SENT_OTP_PAGE
                });
              }}
              className={classes.learnMore}
            >
              Change Mobile Number
            </Link>
          </p>
        </div>

        <div className={classes.codeMobileNumberContainer}>
          {fields.map(
            (ref: React.RefObject<HTMLInputElement>, index: number) => {
              return (
                <div className={classes.codeInputContainer} key={index}>
                  <input
                    className={classes.inputBox}
                    id={`otpInp${index}`}
                    key={index}
                    name={String(index)}
                    maxLength={1}
                    // placeholder="-"
                    // style={index < 5 ? { marginRight: '16px' } : {}}
                    // className={errorVerifyOtp ? `${classes.disabledTextFieldHelper}` : classes.textFieldHelper}
                    // size="small"
                    onChange={handleChange}
                    onFocus={() => {
                      setCurrentIndex(index);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              );
            }
          )}
        </div>
        {errorMessage && errorMessage !== '' && (
          <div style={{ marginTop: '24px' }}>
            <label className={classes.error}>{errorMessage}</label>
          </div>
        )}
        <p className={classes.mentionMobileNumber}>
          OTP sent on your provided mobile number.
        </p>
        <div className={classes.resendOtpSecondsContainer}>
          <Link
            to={''}
            onClick={handleResend}
            aria-disabled={remainingSeconds > 0}
            className={classes.mentionMobileNumber}
          >
            Resend OTP
          </Link>
          <label className={classes.resendOtpSeconds}>
            {' '}
            {remainingSeconds} s
          </label>
        </div>
        <button className={classes.sendOtpBtn} onClick={handleVerifyOtp}>
          {' '}
          Confirm{' '}
          <img
            className={classes.arrow_forward}
            src={`${process.env.PUBLIC_URL}/keyboard_forward_arrow.png`}
            alt="expand-more"
          />
        </button>
      </div>
    </>
  );
};
