import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './sendOtpComponentStyle';
import { useCancelMemberShip } from '../../../hooks/useCancelMemberShip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PAGE_NAME } from '../../../redux/cancelMemberShip/types';
import ReCAPTCHA from 'react-google-recaptcha';
import { countryList, type IcountryCodeItem } from '../../../constants';

export const SendOtpComponent: React.FC<any> = () => {
  const classes = useStyles();
  const recaptcha = useRef<any>();

  const { setCancelMembershipComponentStates, resetRequestOtp } =
    useCancelMemberShip();

  const { otpSent, errorSendingOtp, sendOtp, phoneCountryCode } =
    useCancelMemberShip();

  const [mobile, setMobile] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [showSendOtp, setShowSendOtp] = useState<boolean>(false);

  useEffect(() => {
    if (otpSent) {
      resetRequestOtp();
      setShowSendOtp(false);
      setCancelMembershipComponentStates({
        pageName: PAGE_NAME.VERIFY_OTP_PAGE,
        token: ''
      });
    }
  }, [otpSent]);

  useEffect(() => {
    const FilteredCountryByPhoneCode: IcountryCodeItem = countryList?.filter(
      (country: IcountryCodeItem) => {
        return country?.phoneCode === phoneCountryCode;
      }
    )[0];
    setCancelMembershipComponentStates({
      countryCode: FilteredCountryByPhoneCode?.countryCode
    });
  }, [phoneCountryCode]);

  useEffect(() => {
    if (errorSendingOtp && errorSendingOtp?.message !== '') {
      setErrorMessage(errorSendingOtp.message);
      setTimeout(() => {
        resetRequestOtp();
      }, 2000);
    }
  }, [errorSendingOtp]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const { value } = event.target;
    setMobile(value);
  };

  const handleSubmit = () => {
    setErrorMessage('');
    if (mobile && mobile !== '') {
      sendOtp({
        mobile,
        phoneCountryCode
      });
    } else {
      setErrorMessage('Invalid mobile number');
    }
  };

  const handleCountryChange = (event: any) => {
    const { value } = event.target;
    setCancelMembershipComponentStates({ phoneCountryCode: value });
  };

  const onChange = (e: any) => {
    if (e) {
      setShowSendOtp(true);
    } else {
      setShowSendOtp(false);
    }
  };

  const onErrored = () => {
    setShowSendOtp(false);
  };

  const onExpired = () => {
    setShowSendOtp(false);
  };

  return (
    <>
      <div>
        <label className={classes.confirmTitle}>
          Confirm your cancellation
        </label>
      </div>
      <div style={{ marginTop: '24px' }}>
        <label className={classes.confirmSubTitle}>
          If you cancel your Cropwise subscription, it will be taken offline
          immediately.
        </label>
      </div>
      {errorMessage && errorMessage !== '' && (
        <div style={{ marginTop: '24px' }}>
          <label className={classes.error}>{errorMessage}</label>
        </div>
      )}

      <div>
        <p className={classes.inputLabel}>Country*</p>
        <Select
          labelId="demo-simple-select-label"
          fullWidth
          placeholder="Select"
          id="demo-simple-select"
          className={classes.selectInputBox}
          displayEmpty
          style={{ color: phoneCountryCode === '' ? '#a3a4a8' : 'black' }}
          value={phoneCountryCode}
          onChange={handleCountryChange}
        >
          {countryList && countryList?.length > 0
            ? (
                countryList.map((option: any) => (
              <MenuItem
                key={`selectopt_${option.phoneCode}`}
                value={option.phoneCode}
              >
                {option.name}
              </MenuItem>
                ))
              )
            : (
            <div style={{ paddingLeft: '16px' }}>No data available</div>
              )}
        </Select>
      </div>
      <div>
        <div className={classes.codeMobileNumberContainer}>
          <div className={classes.codeInputContainer}>
            <p className={classes.inputLabel}>Code*</p>
            <input className={classes.inputBox} value={phoneCountryCode} />
          </div>
          <div className={classes.mobileInputContainer}>
            <p className={classes.inputLabel}>Mobile Number*</p>
            <input
              className={classes.inputBox}
              onChange={handlePhoneChange}
              value={mobile}
              placeholder="Enter Mobile Number"
            />
          </div>
        </div>
        <p className={classes.mentionMobileNumber}>
          We will send you an OTP on above mentioned mobile number
        </p>

        <div className={classes.captchaStyles}>
          <ReCAPTCHA
            ref={recaptcha}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''}
            onChange={onChange}
            onErrored={onErrored}
            onExpired={onExpired}
          />
        </div>
        <button
          className={
            !showSendOtp ? classes.sendOtpBtnDisabled : classes.sendOtpBtn
          }
          onClick={handleSubmit}
          disabled={!showSendOtp}
        >
          {' '}
          Send OTP{' '}
          <img
            className={classes.arrow_forward}
            src={`${process.env.PUBLIC_URL}/keyboard_forward_arrow.png`}
            alt="expand-more"
          />
        </button>
      </div>
    </>
  );
};
