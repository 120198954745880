import React, { useState } from 'react';
import { useStyles } from './staticDataComponentStyle';

interface IstaticDataItemContent {
  label: string
  sublabel: string
  icon: string
  expandData: IexpandDataItemContent
}
const staticDataContent: IstaticDataItemContent[] = [{
  label: 'Device or other IDs',
  sublabel: 'Device or other IDs',
  icon: 'Group',
  expandData: {
    purposeTitle: 'Data collected and for what purpose',
    dataCategory: [{
      categoryLabel: 'Device or other IDs',
      categoryValue: 'Optional',
      categoryDescription: 'App functionality'
    }]
  }
}, {
  label: 'Messages',
  sublabel: 'SMS or MMS',
  icon: 'email',
  expandData: {
    purposeTitle: 'Data collected and for what purpose',
    dataCategory: [{
      categoryLabel: 'SMS or MMS',
      categoryValue: 'Optional',
      categoryDescription: 'App functionality'
    }]
  }
}, {
  label: 'Photo and videos',
  sublabel: 'Photos',
  icon: 'photo_library',
  expandData: {
    purposeTitle: 'Data collected and for what purpose',
    dataCategory: [{
      categoryLabel: 'Photos',
      categoryValue: 'Optional',
      categoryDescription: 'App functionality'
    }]
  }
}, {
  label: 'Personal info',
  sublabel: 'Name, Address and Phone number',
  icon: 'user',
  expandData: {
    purposeTitle: 'Data collected and for what purpose',
    dataCategory: [{
      categoryLabel: 'Name',
      categoryValue: '',
      categoryDescription: 'App functionality, Analytics, Developer communications, Account management'
    }, {
      categoryLabel: 'Address',
      categoryValue: '',
      categoryDescription: 'App functionality, Analytics, Account management'
    }, {
      categoryLabel: 'Phone number',
      categoryValue: '',
      categoryDescription: 'App functionality, Analytics, Developer communications, Account management'
    }]
  }
}, {
  label: 'Location',
  sublabel: 'Approximate location and Precise location',
  icon: 'location_on',
  expandData: {
    purposeTitle: 'Data collected and for what purpose',
    dataCategory: [{
      categoryLabel: 'Approximate location',
      categoryValue: 'Optional',
      categoryDescription: 'App functionality'
    }, {
      categoryLabel: 'Precise location',
      categoryValue: 'Optional',
      categoryDescription: 'App functionality'
    }]
  }
}];

interface IexpandDataItemContent {
  purposeTitle: string
  dataCategory: IdataCategory[]
}

interface IdataCategory {
  categoryLabel: string
  categoryValue: string
  categoryDescription: string
}

export const StaticDataComponent: React.FC<any> = () => {
  const classes = useStyles();

  const [selectedStaticDivIndexes, setselectedStaticDivIndexes] = useState<Array<string | number>>([]);

  const renderStaticData = (staticDataItemContent: IstaticDataItemContent, index: number) => {
    return (
      <>
        <div className={classes.staticDataItemContainer} onClick={() => {
          if (selectedStaticDivIndexes.includes(index)) {
            let tempselectedStaticDivIndexes = [...selectedStaticDivIndexes];
            tempselectedStaticDivIndexes = tempselectedStaticDivIndexes?.filter((divIndex: number | string) => divIndex !== index);
            setselectedStaticDivIndexes([...tempselectedStaticDivIndexes]);
          } else {
            setselectedStaticDivIndexes([...selectedStaticDivIndexes, index]);
          }
        }}>
          <div className={classes.staticDataImg}>
            <img src={`${process.env.PUBLIC_URL}/${staticDataItemContent?.icon}.png`} alt='expand-more' />
          </div>
          <div className={classes.staticDataTitleContainer}>
            <label className={classes.staticDataTitle}>
              {staticDataItemContent?.label}
            </label>
            <label className={classes.staticDatasubTitle}>
            {staticDataItemContent?.sublabel}
            </label>
          </div>
          <img
            className={classes.arrow_down}
            src={`${process.env.PUBLIC_URL}/keyboard_arrow_down.png`}
            alt='expand-more'
          />
        </div>
        {selectedStaticDivIndexes?.includes(index) ? renderExpandData(staticDataItemContent?.expandData, index) : null}
      </>
    );
  };

  const renderExpandData = (expandData: IexpandDataItemContent, index: number) => {
    return (
      <div className={classes.expandDataItemContainer} onClick={() => {
        let tempselectedStaticDivIndexes = [...selectedStaticDivIndexes];
        tempselectedStaticDivIndexes = tempselectedStaticDivIndexes?.filter((divIndex: number | string) => divIndex !== index);
        setselectedStaticDivIndexes([...tempselectedStaticDivIndexes]);
      }}>
        <div className={classes.expandDataTitleContainer}>
          <div className={classes.purposeTitleContainer}>
            <label className={classes.purposeTitle}>
              {expandData?.purposeTitle}
            </label>
            <img
              className={classes.error_outline}
              src={`${process.env.PUBLIC_URL}/error_outline.png`}
              alt='expand-more'
            />
          </div>
          {expandData?.dataCategory?.map((dataCategoryItem: IdataCategory) => <>
          <div className={classes.expandsubTitleContainer}>
            <label className={classes.expandsubTitle}>
              {dataCategoryItem?.categoryLabel}
            </label>
            <label className={classes.otionalLabel}>{dataCategoryItem?.categoryValue}</label>
          </div>
          <label className={classes.appFunctionalitylabel}>
           {dataCategoryItem?.categoryDescription}
          </label>
          </>)}
        </div>
      </div>
    );
  };
  return <>
    <div className={classes.dataContainer}>
            <div className={classes.lossDataContainer}>
              <div className={classes.cloudImgContainer}>
                <img src={`${process.env.PUBLIC_URL}/cloud-computing.png`} alt='expand-more' className={classes.cloudImg} />
              </div>
              <div>
                <label className={classes.lassDataTitle}>
                  You will lose below data
                </label>
                <label className={classes.lassDatasubTitle}>
                  We have collected below data earlier
                </label>
              </div>
            </div>
            {staticDataContent?.map((staticDataItemContent: IstaticDataItemContent, index: number) => renderStaticData(staticDataItemContent, index))}

          </div>
    </>;
};
