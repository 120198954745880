import { type AxiosRequestConfig } from 'axios';
import { axiosApiInstance } from './axiosInterceptor';

export const contentType = 'application/json';

export const getApiRequest = async (
  url: string,
  config?: AxiosRequestConfig | undefined
) => {
  return await axiosApiInstance
    .get(url, {
      ...config
    })
    .then(response => {
      return response.data;
    })
    .catch((error: any) => {
      // const errorResponse = error as AxiosError;
      if (error === 'User not found') {
        const errObj = { errorCode: '', message: '' };
        errObj.errorCode = '400';
        errObj.message = 'User not found';
        handleErrorResponses(errObj);
      } else if (error) {
        error.errorCode = '403';
      }
      handleErrorResponses(error);
    });
};

export const postApiRequest = async (
  url: string,
  data?: unknown | undefined,
  config?: AxiosRequestConfig | undefined
) => {
  return await axiosApiInstance
    .post(url, data, {
      ...config
    })
    .then(response => {
      return response.data;
    })
    .catch((error: unknown) => {
      handleErrorResponses(error);
    });
};

export const putApiRequest = async (
  url: string,
  data?: unknown | undefined,
  config?: AxiosRequestConfig | undefined
) => {
  return await axiosApiInstance
    .put(url, data, {
      headers: {},
      ...config
    })
    .then(response => {
      return response.data;
    })
    .catch((error: unknown) => {
      handleErrorResponses(error);
    });
};

export const patchApiRequest = async (
  url: string,
  data?: unknown | undefined,
  config?: AxiosRequestConfig | undefined
) => {
  return await axiosApiInstance
    .patch(url, data, {
      headers: {},
      ...config
    })
    .then(response => {
      return response.data;
    })
    .catch((error: unknown) => {
      handleErrorResponses(error);
    });
};

export const deleteApiRequest = async (
  url: string,
  config?: AxiosRequestConfig | undefined
) => {
  return await axiosApiInstance
    .delete(url, {
      headers: {},
      ...config
    })
    .then(response => {
      return response.data;
    })
    .catch((error: unknown) => {
      // const errorResponse = error as AxiosError;
      handleErrorResponses(error);
    });
};

const handleErrorResponses = (error: unknown) => {
  throw error;
};
